import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import 'moment/locale/zh-cn';
import './index.less';
import asyncComponent from './components/async-component';

const placeholder = <span style={{ position: 'fixed', width: '100%', height: '100%', display: 'flex', alignItems: 'center', 'justifyContent': 'center' }}>loading……</span>

const PageMaps = asyncComponent(() => import('./pages/maps'), placeholder);
const PageLogin = asyncComponent(() => import('./pages/login'), placeholder);
const PageHome = asyncComponent(() => import('./pages/home'), placeholder);
const PageAddress = asyncComponent(() => import('./pages/address'), placeholder);
const PageRecord = asyncComponent(() => import('./pages/record'), placeholder);
const PageExport = asyncComponent(() => import('./pages/export'), placeholder);

const PageAdmin = asyncComponent(() => import('./pages/admin'), placeholder);
const PageSystemLogs = asyncComponent(() => import('./pages/systemLogs'), placeholder);
const PagePermission = asyncComponent(() => import('./pages/permission'), placeholder);
const PageRole = asyncComponent(() => import('./pages/role'), placeholder);



const App = () => (
  <Router>
    <Switch>
      <Route exact path="/"><PageHome /></Route>
      <Route exact path="/maps"><PageMaps /></Route>
      <Route exact path="/address/list"><PageAddress /></Route>
      <Route exact path="/record/list"><PageRecord /></Route>
      <Route exact path="/export/list"><PageExport /></Route>
      <Route exact path="/login"><PageLogin /></Route>
      <Route exact path="/admin/list"><PageAdmin /></Route>
      <Route exact path="/logs/list"><PageSystemLogs /></Route>
      <Route exact path="/permission/list"><PagePermission /></Route>
      <Route exact path="/role/list"><PageRole /></Route>
      <Route render={() => (<Redirect to="/result/404" />)} />
    </Switch>
  </Router>
);

ReactDOM.render(<App />, document.getElementById('root'));
